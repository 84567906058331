import Modal from "components/atomic-components/atoms/layout-elements/Modal/Modal";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import { OutsideLinkModalStyled } from "./OutsideLinkModalStyled";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  url: string;
}

const OutsideLinkModal = ({ isOpen = false, onClose, url }: Props) => {
  const closeModal = () => {
    onClose?.();
  };

  const onContinue = () => {
    window.open(url, "_ blank");
    closeModal();
  };

  return (
    <Modal showCloseButton isOpen={isOpen} onClose={closeModal}>
      <OutsideLinkModalStyled>
        <Headings className="heading" lineHeight={42} mobileFontSize={24} mobileLineHeight={24} variant="primaryHeading3">
          We are routing you to another site
        </Headings>
        <Paragraph className="paragraph" variant="secondaryParagraph3">
          We are routing your request for more information about available opportunities to the restaurant you selected!
        </Paragraph>
        <Button className="cta" variant="modalOutlineButton" onClick={onContinue}>
          Continue
        </Button>
      </OutsideLinkModalStyled>
    </Modal>
  );
};

export default OutsideLinkModal;

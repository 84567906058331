import styled from "styled-components";

export const OutsideLinkModalStyled = styled.div<{ isBackButton?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.primary.coleslawWhite};
  padding: 64px 0 60px;
  width: 632px;
  max-height: 355px;
  text-align: center;
  gap: 20px;
  border-radius: 20px;

  .heading {
    max-width: 383px;
    margin: auto;
  }

  p {
    max-width: 383px;
    margin: auto;
  }

  .cta {
    flex-shrink: 0;
    margin: auto;
    width: 162px;
    height: 44px;
    border: 3px solid ${(props) => props.theme.color.primary.foundersBlue};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 20px;
    font-weight: 700;
    line-height: 21.6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    padding: 30px 24px 32px;
    width: 272px;
    max-height: 276px;

    .heading {
      margin-top: 24px;
    }

    .cta {
      font-size: 15px;
      line-height: 18px;
      width: 148px;
      height: 36px;
    }
  }
`;

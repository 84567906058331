import { ParsedUrlQuery } from "querystring";
import { GetServerSidePropsContext } from "next";
import PageWrapper from "components/global/PageWrapper/PageWrapper";
import { getPageProps, SEOData } from "utils/cms/pageHelper";
import Head from "components/global/Head/Head";
import RestaurantOpportunitiesContainer from "containers/RestaurantOpportunities/RestaurantOpportunities";
interface Props {
  finalSEO?: SEOData;
}

const defaultSEO = {
  title: "Zaxbys - Job Application",
  description: "Join one of many Indescribably good teams. We can't keep up this much flavor all on our ow",
};

export const getServerSideProps = async (context: GetServerSidePropsContext<ParsedUrlQuery>) => {
  return getPageProps(context, defaultSEO);
};

const RestaurantOpportunities = ({ finalSEO }: Props) => {
  const ogURL = `${process.env.NEXT_PUBLIC_HOST}/careers/application`;
  return (
    <PageWrapper>
      <Head {...finalSEO} ogURL={ogURL} />
      <RestaurantOpportunitiesContainer />
    </PageWrapper>
  );
};
export default RestaurantOpportunities;

import styled from "styled-components";

export const TemplateTopContentStyled = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .subtitle {
    color: ${(props) => props.theme.color.core.white};
    padding: 10px 29px 31px;
  }

  .title,
  .subtitle {
    position: relative;
    z-index: 2;
  }

  .title {
    width: 100%;
    color: ${(props) => props.theme.color?.core.white};
    text-align: center;
  }

  .topContentImage {
    z-index: 1;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    padding: 40px 24px;
    .title {
      width: 342px;
      font-family: ${(props) => props.theme.font.barlow};
      text-align: center;
      font-size: 36px;
      font-weight: 700;
    }
    .subtitle {
      margin-top: 12px;
      padding: 0 33px;
      font-size: 14px;
      font-weight: 500;

      text-align: center;
    }
  }
`;

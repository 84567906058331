import styled, { css } from "styled-components";

const datePickerVariant = css`
  height: 33px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  .dropdown-label {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
`;

const vechicleVariant = css`
  height: 37px;
`;

export const DropdownWrapper = styled.div<{ zIndex?: number }>`
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  width: 100%;
  z-index: ${(props) => props.zIndex};
  .deleteText {
    font-size: 12px;
    line-height: 13.2px;
    font-weight: 800;
    color: ${(props) => props.theme.color.primary.tongueTorchRed};
    font-family: ${(props) => props.theme.font.barlow};
    .errorMessage {
      font-weight: 600;
      font-family: ${(props) => props.theme.font.barlow};
    }
  }
`;

export const DropdownHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: ${(props) => props.theme.color.primary.foundersBlue};
  ${(props) => props.theme.responsive.max.lg} {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    height: 28px;
    width: 100%;
  }
  .icon-wrapper {
    position: relative;
    width: 16px;
    height: 16px;
  }
  ${({ isOpen }) => isOpen && `border-radius: 5px 5px 0px 0px;`}
`;

export const SelectedOption = styled.h4`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  color: ${(props) => props.theme.color.core.white};
  font-family: ${(props) => props.theme.font.barlow};
  width: calc(100% - 5%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) => props.theme.responsive.max.lg} {
    color: ${(props) => props.theme.color.primary.foundersBlue};
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
`;

export const OptionsList = styled.ul`
  position: absolute;
  width: 100%;
  left: 0;
  background: ${({ theme }) => theme.color.core.white};
  margin-top: 2px;
  list-style: none;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 1002;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  .optionNameWrapper {
    display: flex;
    width: 60px;
    justify-content: space-between;
  }
`;

export const OptionItem = styled.li<{
  className: string;
  variant?: "datePickerVariant" | "vechicleVariant" | string;
  isFromCheckout?: boolean;
}>`
  height: 37px;
  padding: 6px 16px;
  border-bottom: 1px solid #bbbcbc;
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  background: ${(props) => props.theme.color.core.white};
  align-items: center;
  cursor: pointer;
  .remove-icon-wrapper {
    position: relative;
    width: 16px;
    height: 16px;
  }

  &:focus {
    background-color: ${(props) => props.theme.color.gray.boxShadow};
    border: none;
    outline: none;
  }

  .dropdown-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    font-family: ${(props) => props.theme.font.barlow};
    color: ${(props) => props.theme.color.primary.foundersBlue};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: ${({ isFromCheckout }) => !isFromCheckout && "calc(100% - 8%)"};
    display: flex;
    align-items: center;
  }
  &.active {
    background-color: ${(props) => props.theme.color.primary.tongueTorchRed};
    .dropdown-label {
      color: ${(props) => props.theme.color.core.white};
      font-weight: 700;
    }
  }
  &.add-button {
    min-height: 52px;
    justify-content: center;
    align-items: center;
    .select-button {
      height: 28px;
      font-size: 15px;
      font-weight: 700;
    }
  }
  ${(props) => props.theme.responsive.max.lg} {
    min-height: 34px;
    &.add-button {
      .select-button {
        width: 100%;
      }
    }
  }
  ${(props) => ({ vechicleVariant, datePickerVariant }[props?.variant ?? ""])}
`;

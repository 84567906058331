import { apiCall } from "src/apis/api";
import { CommonLayerResponseData } from "src/types/types";
import { NOTIFICATIONS } from "../../endpoints";

interface Request {
  storeName?: string; // storeName ?: city - address
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  hiringContact?: string;
  firstName: string;
  lastName: string;
  jobProfile: string;
  email: string;
  phone: string;
}

export const submitRestaurantOpportunityApplication = async (data: Request) => {
  return apiCall({
    requestType: "POST",
    apiEndPoint: `${NOTIFICATIONS}/stores/careers`,
    apiPostData: data,
  }) as Promise<CommonLayerResponseData>;
};

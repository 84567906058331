import React, { MouseEvent, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import { CHEVRON_SHARP_LEFT, CLOSE_X, CLOSE_WHITE_X, BLACK_CHEVRON_SHARP_LEFT } from "assets/images";
import Button from "components/atomic-components/atoms/button/Button";
import { DEFAULT_NUMBER_OF_VEHICLES } from "constants/constant";
import { DropdownWrapper, DropdownHeader, SelectedOption, OptionItem, OptionsList } from "./DropdownStyled";
import { DropdownItemTypes, DropdownProps } from "./types";

const defaultValue: DropdownItemTypes = {
  value: "",
  label: "Select Options",
};

function Dropdown({
  value = defaultValue,
  showRemove,
  handleChange,
  buttonAction,
  showAddButton,
  handleRemove,
  buttonText = "click",
  options,
  errorMessage,
  variant = "",
  isDisabled = false,
  placeholder = "",
  isFromCheckout = false,
  zIndex = 1000,
}: DropdownProps) {
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));
  const splittedErrorMessage = errorMessage?.split("%");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const [optionValues, setOptionValues] = useState(options);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const removeItem = (e: React.MouseEvent<HTMLElement>, option: DropdownItemTypes) => {
    e.stopPropagation();
    if (selectedOption?.value === option.value) {
      setSelectedOption(defaultValue);
    }
    setOptionValues(optionValues?.filter((optn) => option.value !== optn.value));
    handleRemove?.(option);
  };

  const handleSelectOption = (option: DropdownItemTypes) => {
    setSelectedOption(option);
    handleChange?.(option);
    setIsOpen(false);
  };

  useEffect(() => {
    setOptionValues(options);
  }, [options]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value, options]);

  useEffect(() => {
    if (isOpen && selectedOption) {
      const selectedElement = document.getElementById(`dropdown-option-${selectedOption.value}`);
      selectedElement?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [isOpen, selectedOption]);

  useEffect(() => {
    if (dropdownRef.current) {
      const handleClickOutside = (event: MouseEvent<HTMLElement> | any) => {
        if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [dropdownRef.current]);

  const getChevronDegree = () => {
    if (selectedOption.value === "" && placeholder === "") {
      return 0;
    }
    return isOpen ? 270 : 90;
  };
  const returnFormatedDate = (option: any) => {
    if (isFromCheckout && !option.label.startsWith("ASAP")) {
      return (
        <div className="optionNameWrapper">
          <span className="dropdown-label">{option.label.split(" ")?.[0]}</span>
          <span className="dropdown-label">{option.label.split(" ")?.[1]}</span>
        </div>
      );
    } else {
      return <span className="dropdown-label">{option.label}</span>;
    }
  };

  let currentIndex = -1;

  const handleKeyDown = (event: React.KeyboardEvent, option: DropdownItemTypes | null) => {
    if (!isOpen) {
      return;
    }

    const dropdownList = document?.getElementById("dropdownList");
    let listItems = null;
    if (dropdownList) {
      listItems = dropdownList.querySelectorAll("li");
    }

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        if (listItems) {
          currentIndex = (currentIndex + 1) % listItems.length;
          listItems[currentIndex]?.focus();
        }

        break;
      case "ArrowUp":
        event.preventDefault();
        if (listItems) {
          if (currentIndex === -1) currentIndex = 0;
          currentIndex = (currentIndex - 1 + listItems.length) % listItems.length;
          listItems[currentIndex].focus();
        }
        break;
      case "Enter":
        option && handleSelectOption(option);
        break;
      default:
        break;
    }
  };

  return (
    <DropdownWrapper ref={dropdownRef} zIndex={zIndex} className="dropdown-wrapper">
      <DropdownHeader
        onClick={() => setIsOpen(!isOpen)}
        aria-controls="listbox"
        aria-haspopup="listbox"
        isOpen={isOpen && !isDisabled}
        className="dropdown-header"
        tabIndex={0}
        onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event, null)}
      >
        <SelectedOption>{selectedOption?.label || placeholder}</SelectedOption>
        <div className="icon-wrapper">
          <Image
            src={device === "MOBILE" && variant !== "birthdayVariant" ? BLACK_CHEVRON_SHARP_LEFT : CHEVRON_SHARP_LEFT}
            alt="toggle dropdown"
            className="dropdown-arrow"
            layout="fill"
            style={{ transform: `rotate(${getChevronDegree()}deg)` }}
          />
        </div>
      </DropdownHeader>
      {isOpen && !isDisabled && (
        <OptionsList role="listbox" id="dropdownList">
          {optionValues?.map((option: DropdownItemTypes, idx: number) => (
            <OptionItem
              key={option.value}
              tabIndex={0}
              onKeyDown={(event: React.KeyboardEvent) => handleKeyDown(event, option)}
              role="option"
              aria-selected={true}
              id={`dropdown-option-${option.value}`}
              className={option?.value === selectedOption.value ? "active" : ""}
              onClick={() => handleSelectOption(option)}
              variant={variant}
              data-testid={""}
              isFromCheckout={isFromCheckout}
            >
              {returnFormatedDate(option)}

              {showRemove && (
                <div className="remove-icon-wrapper" data-testid="remove-icon" onClick={(e) => removeItem(e, option)}>
                  {option?.value === selectedOption.value ? (
                    <Image src={CLOSE_WHITE_X} alt="close ic" layout="fill" />
                  ) : (
                    <Image src={CLOSE_X} alt="close ic" layout="fill" />
                  )}
                </div>
              )}
            </OptionItem>
          ))}
          {showAddButton && (
            <OptionItem key="add-button" className="add-button">
              {options?.length >= DEFAULT_NUMBER_OF_VEHICLES ? (
                <span className="deleteText">
                  {splittedErrorMessage?.[0]}
                  <span className="errorMessage">{splittedErrorMessage?.[1]}</span>
                </span>
              ) : (
                <Button variant="primaryBlue" onClick={() => buttonAction?.()} className="select-button">
                  {buttonText}
                </Button>
              )}
            </OptionItem>
          )}
        </OptionsList>
      )}
    </DropdownWrapper>
  );
}

export default Dropdown;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/types/types";
import { resetCareerStore, setClearCache } from "redux/reducer/Careers/CareersAction";
import TemplateTopContent from "components/atomic-components/atoms/layout-elements/TemplateTopContent/TemplateTopContent";
import { setRestaurantOpportunitiesData } from "redux/reducer/CMS/CMSAction";
import RestaurantApplication from "components/Restaurant/RestaurantApplication/RestaurantApplication";
import OutsideLinkModal from "components/atomic-components/molecules/Modals/OutsideLinkModal/OutsideLinkModal";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import AccountContactUs from "components/atomic-components/molecules/AccountContactUs/AccountContactUs";
import RestaurantSelectAStore from "components/Restaurant/RestaurantSelectAStore/RestaurantSelectAStore";
import RestaurantConfirmationModal from "components/Restaurant/RestaurantConfirmationModal/RestaurantConfirmationModal";
import RestaurantRedirection from "components/Restaurant/RestaurantRedirection/RestaurantRedirection";
import { submitRestaurantOpportunityApplication } from "api/notifications/stores/submit-restaurant-opportunity-application";
import { RestaurantApplicationValues } from "components/Restaurant/RestaurantApplication/useRestaurantApplication";
import { RestaurantOpportunitiesStyled } from "./RestaurantOpportunitiesStyled";

const RestaurantOpportunities = () => {
  const dispatch = useDispatch();

  const { restaurantOpportunities, isRestaurantOpportunitiesLoaded, careersStore, clearCache } = useSelector(
    ({ cms, careers }: RootState) => ({
      restaurantOpportunities: cms.restaurantOpportunities,
      isRestaurantOpportunitiesLoaded: cms.isRestaurantOpportunitiesLoaded,
      careersStore: careers.careersStore,
      clearCache: careers.clearCache,
    })
  );

  const isStoreSelected = !!careersStore;

  const [hasSelectAStoreError, setHasSelectAStoreError] = useState(false);
  const [isRedirectionModalPresent, setIsRedirectionModalPresent] = useState(false);
  const [isConfirmationModalPresent, setIsConfirmationModalPresent] = useState(false);

  useEffect(() => {
    setIsRedirectionModalPresent(!!careersStore?.hiringUrl);
  }, []);

  const handleSubmit = async (values: RestaurantApplicationValues) => {
    if (!careersStore) {
      setHasSelectAStoreError(true);
      if (window) window.scrollTo(0, 0);
    } else {
      return submitRestaurantOpportunityApplication(values).then((res) => {
        if (res.success) {
          setIsConfirmationModalPresent(true);
        }

        return res;
      });
    }
  };

  const closeConfirmationModal = () => setIsConfirmationModalPresent(false);
  const closeRedirectionModal = () => setIsRedirectionModalPresent(false);

  useEffect(() => {
    if (clearCache) {
      dispatch(resetCareerStore());
    } else {
      dispatch(setClearCache(true));
    }
    dispatch(setRestaurantOpportunitiesData());
  }, []);

  if (!restaurantOpportunities || !isRestaurantOpportunitiesLoaded) return null;

  const renderContentBasedOnHiringUrl = careersStore?.hiringUrl ? (
    <RestaurantRedirection hiringUrl={careersStore.hiringUrl} />
  ) : (
    <RestaurantApplication onSubmit={handleSubmit} formData={restaurantOpportunities.Form} />
  );

  return (
    <RestaurantOpportunitiesStyled>
      {restaurantOpportunities.ConfirmationModal && isConfirmationModalPresent && (
        <RestaurantConfirmationModal
          modalContent={restaurantOpportunities.ConfirmationModal}
          selectedStoreId={careersStore?.storeId}
          onClose={closeConfirmationModal}
        />
      )}
      {isRedirectionModalPresent && careersStore?.hiringUrl && (
        <OutsideLinkModal url={careersStore.hiringUrl} onClose={closeRedirectionModal} isOpen={isRedirectionModalPresent} />
      )}
      {restaurantOpportunities.Head?.Title && restaurantOpportunities.Head?.Background?.url && (
        <TemplateTopContent
          bgUrl={restaurantOpportunities.Head.Background.url}
          bgAlt={restaurantOpportunities.Head.Background.alternativeText || "Background"}
          title={restaurantOpportunities.Head.Title}
          subtitle={restaurantOpportunities.Head.Subtitle}
        />
      )}
      <div className="restaurantOpportunitiesBody">
        <RestaurantSelectAStore
          isStoreSelected={isStoreSelected}
          storeDetail={careersStore}
          bodyContent={restaurantOpportunities.Body?.Content}
          hasSelectAStoreError={hasSelectAStoreError}
        />
        {isStoreSelected ? (
          renderContentBasedOnHiringUrl
        ) : (
          <Paragraph variant="primaryParagraph1" className="textBlock">
            Most Zaxbys restaurants are independently owned and operated by third party franchisees under license agreements with Zaxbys SPE
            Franchisor LLC (“ZSFL”). You may be applying for employment with an independently owned and operated restaurant. ZSFL has no
            control over employment terms and conditions at independently owned and operated restaurants. Questions regarding employment at
            independently owned and operated restaurants must be directed to such the third-party franchisee and not ZSFL. By applying for a
            job at a franchisee operated restaurant, the information you provide will be forwarded to the franchisee organization in order
            for that organization to reach out to you and process and evaluate your application. ZSFL will have no involvement in any
            employment decisions regarding you, including whether you receive an interview or whether you are hired to work for the
            franchisee. You must contact the franchise organization for information about its privacy practices.
          </Paragraph>
        )}
      </div>
      <AccountContactUs hasUnderline className="contactUsFooter" />
    </RestaurantOpportunitiesStyled>
  );
};

export default RestaurantOpportunities;

import Link from "components/atomic-components/atoms/typography/Links/Links";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Button from "components/atomic-components/atoms/button/Button";
import { RestaurantRedirectionStyled } from "./RestaurantRedirectionStyled";

interface Props {
  hiringUrl: string;
}

const RestaurantRedirection = ({ hiringUrl }: Props) => {
  return (
    <RestaurantRedirectionStyled>
      <Headings
        variant="primaryHeading2"
        fontSize={36}
        fontWeight={700}
        lineHeight={43}
        mobileFontSize={24}
        mobileLineHeight={37}
        className="title"
      >
        Go to Application
      </Headings>
      <Paragraph variant="primaryParagraph1" className="body">
        Want more information about available opportunities at the Zaxbys location selected above? We’ll route you there - just say so!
      </Paragraph>
      <Link to={hiringUrl}>
        <Button variant="primaryBlue" className="applyButton">
          Apply Online
        </Button>
      </Link>
    </RestaurantRedirectionStyled>
  );
};

export default RestaurantRedirection;

import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import PromptModal from "components/atomic-components/molecules/Modals/PromptModal/PromptModal";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import StackedButtonsContainer from "components/atomic-components/molecules/StackedButtonsContainer/StackedButtonsContainer";
import Link from "components/global/Link/Link";
import { RestaurantOpportunitiesConfirmationModal } from "types/cms/restaurant-opportunities";
import { RestaurantConfirmationModalStyled } from "./RestaurantConfirmationModalStyled";

interface Props {
  modalContent: RestaurantOpportunitiesConfirmationModal;
  selectedStoreId?: number;
  onClose: () => void;
  className?: string;
}

const RestaurantConfirmationModal = ({ modalContent, selectedStoreId, onClose, className }: Props) => {
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));
  const router = useRouter();
  const body = (
    <>
      {modalContent.Title && (
        <Headings className="heading" fontSize={40} lineHeight={42} mobileFontSize={24} mobileLineHeight={24} variant="primaryHeading2">
          {modalContent.Title}
        </Headings>
      )}
      {modalContent.Body && (
        <Paragraph variant="secondaryParagraph3" className="paragraph">
          {modalContent.Body}
        </Paragraph>
      )}
    </>
  );

  const redirectLink = () => {
    router.push(
      {
        pathname: "/menu",
        query: { storeId: selectedStoreId },
      },
      "/menu"
    );
  };

  const footer = (
    <>
      <StackedButtonsContainer direction={device === "DESKTOP" ? "row" : "col"}>
        <Link to="/">
          <Button className="button buttonHome" variant="secondaryWhite">
            GO TO HOME
          </Button>
        </Link>
        <span onClick={redirectLink}>
          <Button className="button" variant="primaryRed">
            EXPLORE MENU
          </Button>
        </span>
      </StackedButtonsContainer>
    </>
  );

  return (
    <RestaurantConfirmationModalStyled className={className}>
      <PromptModal className="modal" isOpen body={body} isCloseRequired footer={footer} onClose={onClose} />
    </RestaurantConfirmationModalStyled>
  );
};

export default RestaurantConfirmationModal;

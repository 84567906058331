import { CommonLayerResponseData } from "src/types/types";
import { Nullable } from "types/cms/cms-types";
import GenericError from "components/atomic-components/atoms/GenericError/GenericError";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import TextField from "components/atomic-components/atoms/form-elements/TextField/TextField";
import Dropdown from "components/atomic-components/atoms/form-elements/Dropdown/Dropdown";
import Button from "components/atomic-components/atoms/button/Button";
import StackedInputsContainer from "components/atomic-components/molecules/StackedInputsContainer/StackedInputsContainer";
import { RestaurantOpportunitiesForm } from "types/cms/restaurant-opportunities";
import { RestaurantApplicationStyled } from "./RestaurantApplicationStyled";
import { useRestaurantApplication, RestaurantApplicationValues } from "./useRestaurantApplication";

interface Props {
  formData: Nullable<RestaurantOpportunitiesForm>;
  onSubmit: (values: RestaurantApplicationValues) => Promise<CommonLayerResponseData | undefined>;
  className?: string;
}
const options = [
  {
    value: "cook",
    label: "Cook",
  },
  {
    value: "cashier",
    label: "Cashier",
  },
  {
    value: "manager",
    label: "Manager",
  },
];
const RestaurantApplication = ({ formData, onSubmit, className }: Props) => {
  const { values, labelForDropdown, errors, errorExists, handleChange, handleDropdownChange, handleSubmit, handleBlur, genricErrors } =
    useRestaurantApplication(onSubmit);

  const APPLICATION_DETAILS = [
    {
      value: values.firstName,
      name: "firstName",
      label: "FIRST NAME",
      maxLength: 25,
      error: errors?.firstName,
    },
    {
      value: values.lastName,
      name: "lastName",
      label: "LAST NAME",
      maxLength: 25,
      error: errors?.lastName,
    },

    {
      value: values.email,
      name: "email",
      label: "EMAIL",
      error: errors?.email,
    },
    {
      value: values.phone,
      name: "phone",
      label: "PHONE",
      error: errors?.phone,
      maxLength: 10,
    },
  ];
  return (
    <RestaurantApplicationStyled className={className}>
      {formData?.Title && (
        <Headings
          variant="headingMedium2"
          fontSize={36}
          fontWeight={700}
          lineHeight={43}
          mobileFontSize={24}
          mobileLineHeight={29}
          className="formTitle"
        >
          {formData.Title}
        </Headings>
      )}
      {formData?.Body && (
        <Paragraph variant="primaryParagraph1" className="formBodyText">
          {formData.Body}
        </Paragraph>
      )}
      <form action="" onSubmit={handleSubmit}>
        <StackedInputsContainer className="inputs">
          {APPLICATION_DETAILS.map((detail: any) => (
            <TextField
              key={detail.name}
              name={detail.name}
              onChange={handleChange}
              value={detail.value}
              error={detail.error}
              maxLength={detail.maxLength}
              className="textInput"
              label={detail.label}
              onBlur={handleBlur}
            />
          ))}
          {genricErrors && (
            <Paragraph variant="secondaryParagraph2" className="requiredText">
              <GenericError>{genricErrors}</GenericError>
            </Paragraph>
          )}
          <Dropdown
            placeholder="CHOOSE A POSITION"
            value={{
              value: values.jobProfile,
              label: labelForDropdown,
            }}
            variant="birthdayVariant"
            errorMessage={errors.jobProfile}
            handleChange={handleDropdownChange}
            options={options}
          />
        </StackedInputsContainer>
        {formData?.Legal && (
          <Paragraph variant="primaryParagraph1" className="legalText">
            {formData.Legal}
          </Paragraph>
        )}

        <Button variant="primaryBlue" type="submit" disabled={errorExists} className="submitButton">
          Submit
        </Button>
      </form>
    </RestaurantApplicationStyled>
  );
};

export default RestaurantApplication;

import styled from "styled-components";

export const RestaurantOpportunitiesStyled = styled.div`
  .restaurantOpportunitiesBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${(props) => props.theme.color.primary.coleslawWhite};
  }

  .contactUsFooter {
    background: ${(props) => props.theme.color.primary.coleslawWhite};
    padding: 60px 0;
    .linkConatct {
      margin-top: 20px;
      .secondParagraph {
        margin-top: 20px;
      }
    }
  }
  .textBlock {
    max-width: 780px;
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .contactUsFooter {
      padding: 32px 0;
      display: block;
    }
    .textBlock {
      max-width: unset;
      padding: 0 27px;
      font-size: 12px;
      line-height: 14.4px;
    }
  }
`;

import { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SingleValue } from "react-select";
import { phoneNumberFormatting } from "utils/index";
import Analytics from "analytics/Analytics";
import { ANALYTICS_CONST } from "constants/analytics";
import { getLocalFieldError } from "utils/validation";
import { cleanPhone } from "utils/cleaning";
import { cleanPhoneNumberFormat } from "utils/helper";
import { CommonLayerResponseData, GenericObject, RootState } from "src/types/types";

export interface RestaurantApplicationValues {
  storeName?: string; // storeName ?: city - address
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  hiringContact?: string;
  firstName: string;
  lastName: string;
  jobProfile: string;
  email: string;
  phone: string;
}

const INITIAL_DATA = {
  storeName: "", // storeName : city - address
  address: "",
  city: "",
  state: "",
  zipcode: "",
  hiringContact: "",
  firstName: "",
  lastName: "",
  jobProfile: "",
  email: "",
  phone: "",
};

export const useRestaurantApplication = (
  onSubmit: (values: RestaurantApplicationValues) => Promise<CommonLayerResponseData | undefined>
) => {
  const { careersStore } = useSelector((state: RootState) => ({
    careersStore: state.careers.careersStore,
  }));

  const [values, setValues] = useState<RestaurantApplicationValues>(INITIAL_DATA);
  const [labelForDropdown, setLabelForDropdown] = useState<string>("");
  const [errors, setErrors] = useState<RestaurantApplicationValues>({
    firstName: "",
    lastName: "",
    jobProfile: "",
    email: "",
    phone: "",
  });
  const [genricErrors, setGenricErrors] = useState("");
  const errorExists =
    !(values.firstName && values.lastName && values.phone && values.email && values.jobProfile) ||
    !!(errors.firstName || errors.lastName || errors.phone || errors.email || errors.jobProfile);

  const getError = (name: string, value: string) => {
    if (name === "firstName" || name === "lastName") {
      if (!value) {
        return "Required Field";
      }
    } else if (name !== "firstName" && name !== "lastName") {
      return getLocalFieldError(name, value);
    }
  };

  const getCleanValue = (name: string, value: string) => {
    if (name === "phone") {
      return cleanPhone(value);
    }
    return value;
  };

  const handleChange = (name: string, value: string) => {
    const clean = getCleanValue(name, value);
    setValues((prev) => ({ ...prev, [name]: clean }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
    setGenricErrors("");
  };

  const handleBlur = (name: string, value: string) => {
    const clean = getCleanValue(name, value);
    if (name === "phone") {
      const formattedPhoneNumber = phoneNumberFormatting(value);
      setValues((prev) => ({ ...prev, phone: formattedPhoneNumber || "" }));
    }
    setErrors((prev) => ({ ...prev, [name]: getError(name, clean) }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const errs = INITIAL_DATA;

    for (const [name, value] of Object.entries(values)) {
      errs[name as keyof RestaurantApplicationValues] = getError(name, value) ?? "";
    }

    if (errs.firstName || errs.lastName || errs.jobProfile || errs.email || errs.phone) {
      setErrors(errs);
      return;
    }
    const apiData = { ...values };
    if (apiData.phone) {
      apiData.phone = cleanPhoneNumberFormat(values.phone);
    }

    onSubmit(apiData).then((response: any) => {
      // Added submit_job_form event
      if (response?.success) {
        Analytics.getInstance().logEvent(ANALYTICS_CONST.CAREERS.SUBMIT_JOB_FORM);
        setValues(INITIAL_DATA);
        setErrors({
          firstName: "",
          lastName: "",
          jobProfile: "",
          email: "",
          phone: "",
        });
        setLabelForDropdown("");
      } else {
        setGenricErrors(response?.error?.message || "Something went wrong");
      }
    });
  };

  const handleDropdownChange = (newValue?: SingleValue<GenericObject>) => {
    if (newValue?.value) {
      handleChange("jobProfile", newValue.value);
      setLabelForDropdown(newValue.label);
      setErrors((prev) => ({ ...prev, jobProfile: newValue.value ? "" : "Required Field" }));
    }
  };

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      storeName: careersStore?.storeName ? careersStore?.storeName : `${careersStore?.city} - ${careersStore?.address}`, // storeName : city - address
      address: careersStore?.address,
      city: careersStore?.city,
      state: careersStore?.state,
      zipcode: careersStore?.zip,
      hiringContact: careersStore?.hiringEmail,
    }));
  }, [careersStore]);

  return {
    values,
    labelForDropdown,
    errors,
    errorExists,
    handleChange,
    handleBlur,
    handleSubmit,
    handleDropdownChange,
    genricErrors,
  };
};

import styled from "styled-components";

export const RestaurantApplicationStyled = styled.div`
  background: ${(props) => props.theme.color.core.white};
  padding: 60px;
  z-index: 1;

  .formTitle {
    font-family: ${(props) => props.theme.font.barlow};
  }
  &,
  form,
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .formBodyText {
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    margin: 32px 0;
    padding: 0 30px;
    max-width: 590px;
  }
  .input-wrapper {
    width: 428px;
  }
  .inputs {
    margin-bottom: 32px;

    .textInputGroupClass {
      width: 428px;
    }

    .requiredText {
      width: 386px;
      margin-top: 20px;
    }
  }

  .dropdown-wrapper {
    width: 428px;
    .dropdown-header {
      h4 {
        font-weight: 800;
      }
    }
  }

  .legalText {
    max-width: 780px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    margin-bottom: 32px;
  }
  .submitButton {
    width: 132px;
    height: 44px;
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    padding: 32px 0;

    .dropdown-wrapper {
      width: 294px;
      .dropdown-header {
        background: ${(props) => props.theme.color.primary.foundersBlue};
        padding: 6px 12px;
        h4 {
          color: ${(props) => props.theme.color.core.white};
          font-size: 16px;
          font-weight: 700;
        }
      }
      .dropdown-label {
        font-size: 13px;
      }
    }
    .inputs {
      margin-bottom: 28px;
      .textInput {
        font-size: 16px;
      }
      .inputLabel {
        font-size: 16px;
      }
    }
    .input-wrapper {
      width: 294px;
    }
    .legalText {
      max-width: 342px;
      font-size: 12px;
      line-height: 14.4px;
      margin-bottom: 28px;
    }
    .submitButton {
      width: 115px;
      height: 36px;
    }

    .formTitle {
      text-align: center;
    }

    .formBodyText {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: center;
      margin: 28px 0;
    }
  }
`;

import Image from "next/image";
import { useDispatch } from "react-redux";
import Link from "components/atomic-components/atoms/typography/Links/Links";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import Button from "components/atomic-components/atoms/button/Button";
import { LOCATION_PIN } from "assets/images";
import Error from "components/global/Error/Error";
import { setIsAlternativeFlow } from "redux/reducer/Location/LocationAction";
import { CareersStore } from "redux/reducer/Careers/types";
import { RestaurantSelectAStoreStyled } from "./RestaurantSelectAStoreStyled";

interface Props {
  bodyContent?: string | null;
  isStoreSelected?: boolean;
  storeDetail: CareersStore | null;
  hasSelectAStoreError?: boolean;
}

const RestaurantSelectAStore = ({ bodyContent, isStoreSelected, storeDetail, hasSelectAStoreError }: Props) => {
  const dispatch = useDispatch();

  const handleLocationsClick = () => {
    dispatch(setIsAlternativeFlow(true));
  };

  const storeSelectedPart =
    isStoreSelected && storeDetail ? (
      <div className="storeSelected">
        <div className="selectedZaxbys">
          <Paragraph variant="primaryParagraph1" className="selectedZaxbysText">
            YOUR SELECTED ZAXBYS
          </Paragraph>
        </div>
        <span className="addressBox" onClick={handleLocationsClick}>
          <div className="iconContainer">
            <Image src={LOCATION_PIN} height={45} width={45} alt="location pin with red underline" className="locationPin" />
          </div>
          <Link to="/locations" className="linkDecoration" containerClassName="addressContainer">
            <div className="address">
              <Paragraph variant="primaryParagraph1" className="addressLine1">
                {storeDetail.address}
              </Paragraph>
              <Paragraph variant="primaryParagraph1" className="addressLine2">
                {storeDetail.city}, {storeDetail.state}, {storeDetail.zip}
              </Paragraph>
            </div>
          </Link>
        </span>
        <span onClick={handleLocationsClick}>
          <Link to="/locations" className="locationsButtonChangeStore">
            <Button variant="primaryBlue" className="selectAStoreButton">
              CHANGE STORE
            </Button>
          </Link>
        </span>
      </div>
    ) : (
      <span onClick={handleLocationsClick}>
        <Link to="/locations" className="locationsButton">
          <Button variant="primaryBlue" className="selectAStoreButton">
            SELECT A STORE
          </Button>
          {hasSelectAStoreError && <Error className="selectAStoreError">Required field</Error>}
        </Link>
      </span>
    );

  return (
    <RestaurantSelectAStoreStyled>
      {bodyContent && (
        <Paragraph variant="primaryParagraph1" className="restaurantOpportunitiesBodyContent">
          {bodyContent}
        </Paragraph>
      )}
      {storeSelectedPart}
    </RestaurantSelectAStoreStyled>
  );
};

export default RestaurantSelectAStore;

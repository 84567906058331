import styled from "styled-components";

export const RestaurantRedirectionStyled = styled.div`
  background: ${(props) => props.theme.color.core.white};
  padding: 60px 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    margin-bottom: 32px;
    font-family: ${(props) => props.theme.font.barlow};
    text-align: left;
  }

  .body {
    margin-bottom: 32px;
    max-width: 529px;
    font-family: ${(props) => props.theme.font.barlow};
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
  }
  .applyButton {
    width: 196px;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    padding: 32px 0;
    .title {
      margin-bottom: 24px;
    }

    .body {
      max-width: unset;
      padding: 0 35px;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 17px;
    }
    .applyButton {
      width: 168px;
      height: 36px;
    }
  }
`;

import styled from "styled-components";

export const RestaurantSelectAStoreStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  .storeSelected {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .addressBox {
    display: flex;
    grid-gap: 9px;
    margin-top: 19.5px;
    align-items: flex-end;
    justify-content: center;
    padding: 0 0 19.5px 18px;
    .iconContainer {
      width: 45px;
      height: 45px;
    }
  }

  .selectedZaxbys {
    width: 285px;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 32px;

    .tooltipIcon {
      display: none;
      position: absolute;
      top: 33px;
      right: -47px;
    }

    .selectedZaxbysText {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }

    .questionMarkIcon:hover {
      cursor: pointer;

      + .tooltipIcon {
        display: block;
      }
    }
  }
  .addressContainer {
    .linkDecoration {
      text-decoration: none;
    }
  }
  .address {
    text-transform: none;
    .addressLine1 {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 16px;
      font-weight: 700;
      line-height: 17.44px;
      text-align: left;
      margin-bottom: 4px;
    }
    .addressLine2 {
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 14px;
      font-weight: 400;
      line-height: 19.95px;
      text-align: left;
    }
  }
  .locationsButton button {
    margin-top: 50px;
    ${(props) => props.theme.responsive.max.md} {
      margin-top: 32px;
    }
  }

  .selectAStoreError {
    font-family: ${(props) => props.theme.font.sourceSansPro};
    color: ${(props) => props.theme.color.secondary.red};
    margin-left: 5px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .selectAStoreButton {
    width: 203px;
    margin-top: 32px;
  }

  .restaurantOpportunitiesBodyContent {
    max-width: 702px;
    text-align: center;
  }
  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    padding: 32px 0;
    .restaurantOpportunitiesBodyContent {
      padding: 0 20px;
      max-width: unset;
      font-size: 14px;
    }
    .addressBox {
      margin-top: 27.5px;
    }
    .selectAStoreButton {
      margin-top: 8px;
      width: 168px;
      height: 36px;
    }
  }
`;

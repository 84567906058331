import styled from "styled-components";

export const RestaurantConfirmationModalStyled = styled.div`
  .cardBody {
    padding-top: 60px;
    height: 267px;
  }
  .heading {
    text-align: center;
    padding: 0 32px;
  }

  .paragraph {
    margin-top: 13px;
    text-align: center;
    padding: 0 85px;
  }

  .whiteTextParagraph {
    color: white;
    margin-bottom: 25px;
  }

  .cardFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px 0 40px;
  }

  .modal {
    height: 404px;
    border-radius: 20px;
    .selectStoreModalContainer {
      background: none;
      background-color: ${(props) => props.theme.color.primary.coleslawWhite};
    }
  }

  .button {
    width: 210px;
  }

  .buttonHome {
    border: 3px solid ${(props) => props.theme.color.core.white};
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .cardBody {
      height: 210px;
      padding: 0;
    }
    .heading {
      padding: 30px 24px 0 24px;
    }
    .paragraph {
      padding: 24px 25px 36px;
      margin-top: 0;
    }
    .cardFooter {
      padding-top: 32px;
      padding-bottom: 41px;
      button {
        margin-bottom: -9px;
      }
    }
    .button {
      width: 176px;
    }
    .modal {
      height: 390px;
      width: 272px;
      .selectStoreModalContainer {
        margin-top: 0;
        width: 100%;
      }
    }
  }
`;
